import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-shipment',
  templateUrl: './shipment.component.html',
  styleUrls: ['./shipment.component.css'],
})
export class ShipmentComponent implements OnInit {
  optionsModel: any;
  settingUpdater: any;
  settings: any;

  // Show / Hide Country Column
  isShown: boolean[] = Array.from({ length: 201 }, (_, i) => i === 1);

  exports: any;

  showLoading: boolean = true;

  constructor(private titleService: Title, private apiService: ApiService) {}

  ngOnInit(): void {
    this.titleService.setTitle('International Shipment | Line Clear Express');
    this.loadData();
    setTimeout(() => {
      this.showLoading = false;
    }, 1000); // 1 second
  }

  loadData(): void {
    this.apiService.getExports().subscribe((response: any) => {
      this.exports = response;
    });
  }

  // Country Cell Color
  getCellStyle(regulation: any) {
    let backgroundColor = '';
    switch (regulation.status) {
      case 'Prohibited':
        backgroundColor = '#FA8072';
        break;
      case 'Export with Limited Quantity':
        backgroundColor = 'lightblue';
        break;
      case 'Restricted':
        backgroundColor = 'orange';
        break;
      default:
        break;
    }
    return { 'background-color': backgroundColor };
  }

  //boolean to hide columns
  toggleShow(index: number) {
    this.isShown[index] = !this.isShown[index];
  }

  // function - dropdown list
  onclick(): void {
    let checkList = document.getElementById('list1');
    checkList.classList.toggle('visible');
  }

  getRegulationsByCountryAndShipment(countryId: number, shipmentId: number) {
    return this.exports.regulations.filter(
      (regulation) =>
        regulation.country_fid === String(countryId) &&
        regulation.shipment_fid === String(shipmentId)
    );
  }
}
